<template>
    <AdminToast/>
    <div class="grid">

        <div class="col-12 md:col-6 lg:col-3">

            <div class="card p-6 h-full flex flex-column">
                <div class="widget-header flex flex-wrap align-items-center justify-content-between pt-1 pb-1">
                    <div class="header-left-section flex align-items-center justify-content-start mb-3 md:mb-0">
                        <div class="profile relative">
                            <img src="http://image.a1catering.com.tr/YoneticiProfil/ProfilDefault.png" width="100" class="border-circle border-3 border-solid"/>
                            <span class="profile-badge absolute">
                                <span class="dot block"></span>
                            </span>
                        </div>
                    </div>
                </div>

            <span>
                <hr>
			</span>

            <span>

				<Button label="Yardım" icon="pi pi-question-circle" @click="visibleLeft = true" class="p-button-danger pr-3 pl-3 pt-2 pb-2" />

				<Sidebar v-model:visible="visibleLeft" :baseZIndex="1000">
					<h3 style="font-weight: normal">Yönetici Ekleme</h3>

                    <p class="text-lg">
						<b>Adı ve Soyadı:</b> Yetkili kişinin adını giriniz Örnek Yazım Şekli: Tarık<br>
						<br>
						<b>E-Posta Adresi:</b> Kuruma ait alan adı uzantılı e-posta adresi giriniz.<br>
						<br>
						<b>GSM:</b> Başında 0 (sıfır) olmadan yetkili kişinin mobil numarasını giriniz.<br>
						<br>
						<b>Şifre:</b> Şifre en az 6 (altı) karakter olmalı ve içinde en az bir büyük harf, bir küçük harf, sayı ve sembolden oluşmalıdır.<br>
						<br>
                        <b>Yönetici Türü:</b> Yöneticiye belirlediğiniz rolü belirleyiniz. Rolü dışında bir yetki türü seçmeyiniz.<br>
						<br>
						<b>Durum:</b> Kullanıcıyı pasif etmeniz durumunda tekrar giriş yapamaz. Pasif olduğunda tarih ve nedenini yazmanız gerekmektedir. Örnek: İşten Ayrılma, Görev Değişimi vs.<br>
						<br>

					</p>

                    <span><hr></span>
                    <span class="text-red-500 font-bold">*</span> doldurulması zorunlu alanlardır.<br>

				</Sidebar>

				</span>

            </div>

        </div>

        <div class="col-12 md:col-6 lg:col-9">
            <div class="card p-fluid p-6 h-full flex flex-column">
                <div class="grid">
                    <div class="col-12">
                        <div class="field grid mb-5">
                            <label class="col-12 mb-2 md:col-2 md:mb-0">Adı<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class="col-12 md:col-10">
                                <InputText id="name" v-model='dataItem.name' type="text" autocomplete='off'/>
                            </div>
                        </div>

                        <div class="field grid mb-5">
                            <label class="col-12 mb-2 md:col-2 md:mb-0">Soyadı<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class="col-12 md:col-10">
                                <InputText id="surName" v-model='dataItem.surName' type="text" autocomplete='off'/>
                            </div>
                        </div>

                        <div class="field grid mb-5">
                            <label class="col-12 mb-2 md:col-2 md:mb-0">E-Posta Adresi<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class="col-12 md:col-10">
                                <InputText id="email" v-model='dataItem.email' type="text" autocomplete='off'/>
                            </div>
                        </div>

                        <div class="field grid mb-5">
                            <label class="col-12 mb-2 md:col-2 md:mb-0">GSM<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class="col-12 md:col-10">
                                <InputText id="gsmNumber" v-model='dataItem.gsmNumber' type="text" autocomplete='off' maxlength="10"/>
                            </div>
                        </div>

                        <div class="field grid mb-5">
                            <label class="col-12 mb-2 md:col-2 md:mb-0">Yönetici Türü<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class="col-12 md:col-10">
                                <Dropdown id="userType" v-model="dataItem.userType" :options="userTypes" optionLabel="name" option-value="value" placeholder="Seçim Yapın" />
                            </div>
                        </div>

                        <div class="field grid mb-5">
                            <label class="col-12 mb-2 md:col-2 md:mb-0">Şifre<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class="col-12 md:col-10">
                                <InputText id="password" v-model='dataItem.password' type="text" autocomplete='off'/>
                            </div>
                        </div>
                        
                        <div class="field grid mb-5">
                            <label class="col-12 mb-2 md:col-2 md:mb-0">Durum</label>
                            <div class="col-12 md:col-10">
                                <InputSwitch v-model="dataItem.isActive" />
                            </div>
                        </div>

                        <div class="field grid">
                            <label class="col-12 mb-2 md:col-2 md:mb-0"></label>
                            <div class="col-12 md:col-10">
                                <Button id='save' label="Kaydet" icon="pi pi-save" @click='save' v-bind:disabled='isProcessing'></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import ManagerUserService from '@/services/managerUserService';
import {showErrorMessage, showValidationMessage} from "@/components/shared/toast/toastFunction";
import UserTypeService from '@/services/userTypeService';
import AdminToast from "@/components/shared/toast/adminToast";
import { checkUserRole } from '../common/commonConstantFunctions';
export default {
    _managerUserService: null,
    _userTypeService: null,
    components: {AdminToast},
    created() {
        checkUserRole(this, "Admin");
        this._managerUserService = new ManagerUserService();
        this._userTypeService = new UserTypeService();
    },
    async mounted() {
        await this.getUserTypes();
    },
    data() {
        return {
            isProcessing: false,
            visibleLeft: false,
            dataItem: {
                name: "",
                surName: "",
                isActive: true,
                email:"",
                gsmNumber:"",
                password:"",
                userType:"",
                passiveDate:"",
                passiveReason:""
            },
            userTypes :[],
        }
    },

    methods: {

        async save() {
            this.isProcessing = true;
            if(this.validateForm())
            {
                let checkResponse = await this._managerUserService.checkManagerUserExists(this.dataItem.gsmNumber, this.dataItem.email);
                if(checkResponse != "Ok")
                {
                    this.isProcessing = false;
                    showValidationMessage(this, checkResponse);
                    return;
                }

                const insertResponse = await this._managerUserService.insertManagerUser(this.dataItem);
                if (insertResponse.isSuccess) {
                    await this.$router.push({ name: "editManagerUser", params: { managerUserId: insertResponse.data } });
                } else {
                    showErrorMessage(this, "Yönetici Eklemede Sorun İle Karşılaşıldı");
                }
            }
            this.isProcessing = false;
        },
        getUserTypes() {
            this.userTypes = this._userTypeService.getUserTypes();
        },
        validateForm() {
            if(this.dataItem.name == "")
            {
                showValidationMessage(this, "Adı Alanı Boş Bırakılamaz");
                return false;
            }

            if(this.dataItem.surName == "")
            {
                showValidationMessage(this, "Soyadı Alanı Boş Bırakılamaz");
                return false;
            }

            if(this.dataItem.email == "")
            {
                showValidationMessage(this, "E-Posta Alanı Boş Bırakılamaz");
                return false;
            }

            if(this.dataItem.userType == "")
            {
                showValidationMessage(this, "Yönetici Türü Alanı Boş Bırakılamaz");
                return false;
            }

            if(this.dataItem.password == "")
            {
                showValidationMessage(this, "Şifre Alanı Boş Bırakılamaz");
                return false;
            }

            if(this.dataItem.gsmNumber == "")
            {
                showValidationMessage(this, "Telefon Numarası Alanı Boş Bırakılamaz");
                return false;
            }

            return true;
        }
    }

}

</script>


<style scoped>

</style>
